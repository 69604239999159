/* Packages */
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
/* Imported Components */
import { Context } from '../../context'
/* Styles */
/* Component */
const SearchEngineOptimization = ({ description = '', lang = 'en', meta = [], title }) => {
  const { site } = useContext(Context)

  const metaDescription = description || site.description
  const defaultMeta = [
    {
      name: 'description',
      content: metaDescription
    },
    {
      property: 'og:title',
      content: title
    },
    {
      property: 'og:description',
      content: metaDescription
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      name: 'twitter:card',
      content: 'summary'
    },
    {
      name: 'twitter:creator',
      content: site.author
    },
    {
      name: 'twitter:title',
      content: title
    },
    {
      name: 'twitter:description',
      content: metaDescription
    }
  ]
  const propMeta = defaultMeta.concat(meta || [])

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={defaultMeta.concat(propMeta)}
    >
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
    </Helmet>
  )
}
/* Exports */
export default SearchEngineOptimization

/* Packages */
import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
/* Imported Components */
/* Styles */

function getObjectKeyArray (obj) {
  if (typeof obj !== 'object') return []
  const arr = []
  for (const key in obj) {
    arr.push(key)
  }
  return arr
}
/* Component */
const ContentfulRichText = ({ document }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (!node.data.target.fields) return
        const { file, description } = node.data.target.fields
        const locales = getObjectKeyArray(file)
        return locales.map(locale => (
          <img
            src={file[locale].url}
            alt={(description && description[locale]) || ''}
            data-locale={locale}
            key={locale}
          />
        ))
      }
    }
  }
  return <div>{documentToReactComponents(document, options)}</div>
}
/* Exports */
export default ContentfulRichText

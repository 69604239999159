/* Packages */
import React from 'react'
/* Imported Components */
import { SearchEngineOptimization } from '../'
/* Styles */
import './styles.scss'
import styles from './layout.module.scss'
/* Component */
const Layout = ({ children, title }) => {
  return (
    <>
      <SearchEngineOptimization title={title} />
      <main className={styles.main}>
        {children}
      </main>
    </>
  )
}
/* Exports */
export default Layout
